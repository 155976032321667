.btnBox {
  display: none;
}
@media screen and (max-width: 768px) {
  .btnBox {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    margin-bottom: 3rem;
  }
  .button {
    padding: 0.7rem 1.5rem;
    font-weight: 400;
    color: #fff;
    background-color: #348ed3;
    border-radius: 1rem;
    border: none;
    margin-right: 0;
    font-size: 1.25rem;
  }
}
