.showcase {
  background-color: #fff;
  position: relative;
}
.sun {
  position: absolute;
  top: 1rem;
  left: 6%;
}
.showcaseBottom {
  position: absolute;
  bottom: 0;
}
.showcaseWrapper {
  display: flex;
  min-height: calc(85vh - 5rem);
  align-items: center;
}
.showcaseContent {
  margin-left: 6%;
  z-index: 1;
}
.amazon {
  display: inline-block;
  position: relative;
  top: 0.4rem;
  margin-left: 0.6rem;
  width: 64px;
}
.contentTitle {
  font-size: 2.5rem;
  margin-bottom: 2rem;
  /* margin-right: 30rem; */
  color: black;
}
.white {
  color: #fff;
}

.titlePlead {
  font-size: 1.875rem;
  margin-top: 3.5rem;
  margin-bottom: 2rem;
  margin-right: 20rem;
}

.contentHeader {
  font-size: 2.5rem;
  margin-bottom: 2rem;
  /* margin-right: 30rem; */
  color: black;
}
.white {
  color: #fff;
}

.plead {
  font-size: 1.875rem;
  margin-top: 3.5rem;
  margin-bottom: 2rem;
}
.action {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.actionL {
  color: #000;
  font-size: 1rem;
  font-weight: 300;
  margin-bottom: 0.625rem;
}
.actionS {
  color: #535461;
  font-size: 0.75rem;
  font-weight: 300;
  margin-top: 0.625rem;
}
.actionBtn {
  color: #fff;
  font-size: 1.5rem;
  padding: 1.5rem 4rem;
  border-radius: 1rem;
  background-color: #348ed3;
}
.featureSection {
  padding: 0.625rem;
  background-color: #e8f6f4;
}
.featureWrapper {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.feature {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.feature p {
  color: #159082;
  font-weight: 300;
  font-size: 0.75rem;
  margin-top: 0.5rem;
}
.feature img {
  height: 4.5625rem;
}
.worldEnd,
.worldEnd3 {
  background-color: #1e1d28;
}
.worldEnd3 {
  background-image: url('../../components/assets/bgstars.svg');
  background-size: 100%;
  position: relative;
}
.worldEndWrapper {
  display: flex;
  position: relative;
  min-height: calc(100vh - 6rem);
  align-items: center;
  justify-content: flex-end;
}
.worldEndContent {
  margin-right: 6%;
  flex-basis: 25rem;
  z-index: 1;
}
.worldendp {
  font-size: 1.125rem;
  color: #fff;
  margin-bottom: 1rem;
  font-weight: 300;
}

.worldEndSvg,
.hifriendSVG {
  position: absolute;
  bottom: 0;
}
.hifriendSVG {
  min-height: 35vh;
  width: 100%;
  left: 5%;
  bottom: 2%;
}

.topMenSvg {
  position: absolute;
  top: 30%;
  left: 45%;
}
.badges,
.badges2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2rem;
}
.connect {
  background-color: #fff;
}
.connectWrapper {
  min-height: 100vh;
  display: flex;
  align-items: center;
}
.boxes {
  display: flex;
  flex-wrap: wrap;
  flex: 0 1 auto;
  z-index: 2;
}
.boxes .connectBox {
  margin-left: 6%;
  flex: 0 0 25.33%;
  display: flex;
  flex-direction: column;
}
.boximg {
  margin: 0 auto;
  margin-bottom: 2.6875rem;
  height: 200px;
  width: auto;
}
.boxcontent {
  text-align: center;
}
.contentTitle {
  margin-bottom: 2rem;
}
.contentpf,
.contentp {
  font-size: 1.25rem;
}
.contentpf {
  margin-bottom: 1rem;
}
.connectbg {
  position: absolute;
  left: 4%;
  top: 9%;
  width: 100%;
}
.build {
  background-color: #fff;
  background-image: url('../../components/assets/buildmask.svg');
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: bottom;
}
.buildWrapper {
  display: flex;
  position: relative;
  min-height: 100vh;
  align-items: center;
  justify-content: flex-start;
}
.buildContent {
  margin-left: 6%;
  flex-basis: 42%;
  z-index: 1;
}
.buildp {
  font-size: 1.25rem;
  margin-bottom: 1rem;
  font-weight: 300;
}
.buildSvg {
  position: absolute;
  bottom: calc(100vh - 95%);
  right: 6%;
}
.GamerSvg {
  position: absolute;
  bottom: calc(100vh - 80%);
  right: -35%;
}

.landing {
  background-color: #1e1d28;
  background-image: url('../../components/assets/bgstars.svg');
  background-size: 100%;
  position: relative;
}
.landingWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-height: 100vh;
}
.landingbg {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 0;
}
.landingbgmobile {
  display: none;
}
.landingbg {
  margin-bottom: 10%;
  z-index: 2;
}
.landingContent,
.cryptoContent {
  flex: 0 0 40%;
  margin-right: 6%;
  z-index: 3;
}
.landingContent > *,
.cryptoContent > * {
  color: #fff;
}
.twobg {
  position: absolute;
  bottom: 0;
  z-index: 0;
  width: 100%;
  z-index: 2;
}
.twobgm {
  display: none;
}
.savebg {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 0;
  max-width: 100%;
}
.testimonialWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-height: 100vh;
}
.testimonials {
  padding: 1.25rem;
  border-radius: 1.5rem;
  height: 75vh;
  overflow-y: scroll;
  z-index: 2;
  flex: 0 0 35%;
  margin-right: 6%;
  background-color: #1e1d28;
}
.testimonials > * {
  color: #fff;
}
.testimonials::-webkit-scrollbar {
  width: 5px;
}
.testimonials::-webkit-scrollbar-track {
  background-color: rgba(52, 142, 211, 0.25);
  border-radius: 1.25rem;
}
.testimonials::-webkit-scrollbar-thumb {
  background-color: #348ed3;
}
.happyend {
  background-color: #fff;
  position: relative;
}
.happyend .sun {
  position: absolute;
  top: 6%;
  left: 6%;
}
.happyendWrapper {
  display: flex;
  position: relative;
  min-height: 100vh;
  align-items: center;
  justify-content: flex-start;
}
.happyendContent {
  margin-left: 6%;
  flex-basis: 42%;
  z-index: 1;
}
.happyend .actionL {
  color: #000000;
}
.brand {
  color: #348ed3;
}

@media screen and (max-height: 800px) {
  .worldendSVG {
    width: 90%;
  }
}

@media screen and (max-width: 1100px) {
  .worldendSVG {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  /*1st Secion*/
  .showcase {
    padding-top: 2rem;
  }
  .sun {
    transform: scale(0.8);
  }
  .showcaseWrapper {
    justify-content: center;
    min-height: 22rem;
  }
  .showcaseContent {
    margin-left: 0;
  }
  .amazon {
    display: flex;
    margin-left: 0;
  }
  .contentHeader {
    font-size: 1.875rem;
    display: inline-block;
    text-align: center;
  }
  .contentHeader span {
    display: flex;
    justify-content: center;
  }
  .plead {
    font-size: 1.5rem;
    text-align: center;
  }
  .showcaseBottom {
    position: static;
  }
  .showcaseBottom img {
    width: 100%;
  }
  .featureSection {
    padding: 1.75rem 1.5rem;
  }

  .feature {
    flex-basis: 25%;
    margin-bottom: 1rem;
  }

  .feature p {
    text-align: center;
  }

  .feature img {
    height: 2.25rem;
  }
  .action {
    align-items: center;
  }
  .actionBtn {
    font-size: 1.5rem;
    padding: 0.85rem 1.5rem;
  }

  .featureWrapper {
    flex-wrap: wrap;
    align-items: start;
  }
  .worldEndWrapper {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 4rem;
  }
  .worldEndContent {
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1.5rem;
    text-align: center;
  }

  .worldEndSvg {
    position: static;
    width: 100%;
  }

  .badges {
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: 16rem;
    margin-left: auto;
    margin-right: auto;
  }
  .badges a {
    flex-basis: 48%;
    margin-bottom: 0.625rem;
  }

  .hifriendSVG {
    position: static;
  }

  .topMenSvg {
    position: absolute;
    top: 4rem;
    left: 80%;
  }

  .badges2 {
    margin-top: 3rem;
    margin-left: 5%;
    margin-right: 5%;
  }

  /*4th Secion*/
  .connectWrapper {
    min-height: 153vh;
    align-items: flex-start;
    padding-bottom: 4rem;
  }
  .boxes {
    min-height: 170vh;
    margin: 4rem 10%;
  }
  .boxes .connectBox {
    flex: 0 0 100%;
  }
  .boximg {
    display: flex;
    justify-content: center;
    margin-bottom: 0;
  }
  .boximg img {
    max-width: 50%;
  }
  .contentTitle {
    margin-bottom: 1.5rem;
  }
  .contentpf,
  .contentp {
    font-size: 1rem;
  }
  .connectbg {
    left: 3.5rem;
    top: 12rem;
    display: flex;
    justify-content: center;
  }
  /*5th Secion*/
  .buildWrapper {
    display: block;
  }

  .buildContent {
    width: 88%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
  .buildp {
    font-size: 1rem;
  }
  .buildWrapper {
    align-items: flex-start;
  }
  .buildSvg {
    position: absolute;
    bottom: 0;
  }
  .dummy {
    padding: 1rem;
  }
  /*6th Section*/
  .landingWrapper {
    justify-content: center;
  }
  .landing {
    padding-top: 4rem;
  }
  .landingContent,
  .cryptoContent {
    flex: 0 0 80%;
    margin-right: 0;
    margin: 0 6% 10%;
    text-align: center;
  }
  .landingbg {
    display: none;
  }
  .landingbgmobile {
    display: block;
    position: relative;
    left: -10%;
  }
  .landingbgmobile img {
    width: 100%;
  }

  /*7th Section*/
  .cryptoContent {
    margin-bottom: 80%;
  }
  .twobg {
    display: none;
  }
  .twobgm {
    position: absolute;
    width: 100%;
    transform: scale(1);
    bottom: 2%;
    left: -10%;
    display: flex;
    justify-content: center;
  }
  .twobgm img {
    width: 100%;
  }

  .testimonialWrapper {
    padding-top: 2.5rem;
    flex-direction: column;
    width: 95%;
    max-width: 95%;
    margin-left: auto;
    margin-right: auto;
  }

  .testimonials {
    margin-right: 0;
    flex: auto;
    height: 50vh;
  }

  .savebg {
    position: static;
  }

  /*9th Section*/
  .happyendWrapper {
    padding-top: 2.5rem;
    min-height: 22vh;
  }
  .happyendContent {
    flex-basis: 90%;
    text-align: center;
  }
}

@media screen and (max-width: 500px) {
  .sun {
    width: 3.75rem;
    height: auto;
    transform: scale(1);
  }
  .showcaseContent {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  .badges2 img {
    max-width: 4rem;
  }
}
