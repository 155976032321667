.container {
  min-height: 800vh;
}
.frontParallax {
  min-height: 800vh;
  padding-top: 10%;
  width: 100%;
  position: absolute;
  top: 0;
}
.backParallax {
  background-image: url('../../components/assets/Rocket.svg');
  background-repeat: no-repeat;
  background-size: auto 80%;
  min-height: 330vh;
  max-width: 1200px;
  position: relative;
  margin-left: 10%;
  z-index: 1;
}
.secionWrapper {
  min-height: 700vh;
}
.techWrapper {
  display: flex;
  position: relative;
  min-height: 100vh;
  align-items: center;
  justify-content: flex-end;
}
.techContent {
  margin-right: 6%;
  flex-basis: 45%;
  z-index: 1;
}
.contentHeader {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: black;
}
.worldendp {
  font-size: 1.25rem;
  color: black;
  margin-bottom: 1rem;
  font-weight: 300;
}

.downloadWrapper {
  display: flex;
  min-height: 100vh;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: 25%;
}
.downloadContent {
  flex-basis: 60%;
  z-index: 1;
  text-align: center;
}
.lastScreen {
  background-color: #ecf8ff;
}

.brand {
  color: #348ed3;
}
.badges {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.badges a {
  width: 25%;
}
.badges a img {
  width: 100%;
  padding: 0;
}

.rocketParts {
  display: none;
}
@media screen and (max-width: 1024px) {
  .backParallax {
    margin-left: 0;
  }
}

@media screen and (max-width: 768px) {
  .contentHeader {
    font-size: 1.87rem;
    text-align: center;
  }
  .worldendp {
    font-size: 1rem;
  }
}

@media screen and (max-width: 700px) {
  .container {
    min-height: 0;
  }
  .section {
    padding: 5% 0;
  }
  .frontParallax {
    min-height: 600vh;
    position: relative;
  }
  .backParallax {
    display: none;
  }
  .secionWrapper,
  .downloadWrapper {
    min-height: auto;
  }
  .techWrapper {
    min-height: auto;
  }
  .techContent,
  .downloadContent {
    flex-basis: 90%;
  }
  .rocketParts {
    display: block;
    padding: 2rem 0;
  }
  .rocketParts img {
    width: 100%;
  }
  .pr40 {
    padding-right: 40%;
  }
  .contentHeader {
    font-size: 1.87rem;
    text-align: center;
  }
  .worldendp {
    font-size: 1rem;
  }
  .badges {
    flex-direction: row;
    flex-wrap: wrap;
    padding: 5% 15%;
  }
  .badges a {
    width: 50%;
  }
}
