@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap');
:root {
  --primary-color: #348ed3;
  --secondary-color: #17a090;
  --bg-light: #ecf8ff;
  --grey-color: #c4c4c4;
  --white-color: #fff;
  --black-color: #000;
  --primary-color-rbga: rgba(52, 142, 211, 0.17);
  --golden-color: #daa520;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
*::after,
*::before {
  box-sizing: inherit;
}

body {
  font-family: 'Roboto', sans-serif;
  line-height: 1.4;
}

h1,
h2,
h3 {
  margin: 0;
}

img {
  display: block;
  max-width: 100%;
}

a {
  text-decoration: none;
  color: #000;
  cursor: pointer;
}

ul {
  list-style-type: none;
}

li {
  list-style: none;
}
