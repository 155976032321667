.mobileNav {
  position: fixed;
  right: -12.5rem;
  top: 0;
  width: 12.5rem;
  height: 100%;
  background-color: #c8c8c8;
  display: flex;
  flex-direction: column;
  transition: right 0.5s ease;
  z-index: 1001;
}

.opened {
  right: 0;
}
