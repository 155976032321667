.testimonial {
  padding-bottom: 1.25rem;
  border-bottom: 0.6px solid #348ed3;
  margin-bottom: 1.25rem;
}

.info {
  display: flex;
  align-items: center;
  margin-bottom: 0.875rem;
}

.country {
  width: 3.125rem;
  height: 3.125rem;
  border-radius: 50%;
}

.person {
  margin-left: 0.25rem;
}

.name {
  margin-bottom: 0.5rem;
}
