/* General */

small {
  display: inline-block;
  color: var(--secondary-color);
  font-size: 0.75rem;
}

.headtitle {
  font-size: 1.5rem;
  font-weight: 500;
  color: var(--secondary-color);
  margin-left: 5%;
  margin-bottom: 1rem;
}

/* Showcase */
.showcase {
  background-color: var(--white-color);
  position: relative;
}

.sun {
  position: absolute;
  top: 0;
  left: 6%;
}

.birds {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 2.5rem;
}

.plan {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding-top: 9rem;
  margin-left: 2.66%;
  margin-right: 2.66%;
}

.planContent {
  margin-bottom: 2.3125rem;
}

.headL {
  font-size: 2.5rem;
  margin-bottom: 0.56.25rem;
  color: var(--black-color);
}

.moneyback {
  font-size: 1.25rem;
  text-align: center;
}
.moneyback > span {
  display: block;
}

/* Prices */

.action {
  display: flex;
  align-items: center;
  margin-bottom: 1.875rem;
}

.price {
  padding: 0 1.5rem;
}

.price p {
  font-size: 1.875rem;
  margin-bottom: -0.5rem;
}

.yearly {
  color: var(--primary-color);
}

.monthly {
  color: var(--secondary-color);
}
.passive {
  color: var(--grey-color);
}

/* CheckBox */
.input input[type='checkbox'] {
  -webkit-appearance: none;
  position: relative;
  width: 50px;
  height: 25px;
  background-color: var(--primary-color);
  outline: none;
  border-radius: 12.5px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  transition: 0.5s;
  margin-bottom: -0.75rem;
  cursor:pointer;
}

.input input:checked[type='checkbox'] {
  background-color: var(--secondary-color);
}

.input input[type='checkbox']::before {
  content: '';
  position: absolute;
  width: 25px;
  height: 25px;
  border-radius: 12.5px;
  top: 0;
  left: 0;
  background-color: var(--white-color);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transform: scale(0.9);
  transition: 0.5s;
}

.input input:checked[type='checkbox']::before {
  left: 25px;
}

/* Price Table */
.pricetable {
  display: flex;
  justify-content: space-between;
  max-width: 76%;
  margin-bottom: 4rem;
}

.box {
  flex: 0 0 30%;
  display: flex;
  flex-direction: column;
}

.boxhead {
  font-size: 1.5rem;
  margin-bottom: 2rem;
  font-weight: bold;
  color: var(--primary-color);
}

.boxhead small {
  display: block;
  color: var(--secondary-color);
  font-size: 0.75rem;
  font-weight: 500;
  margin-top: -0.3rem;
}

.boxPrice {
  display: block;
  font-size: 1.25rem;
  margin-bottom: 1.25rem;
  font-weight: 700;
}

.boxtext {
  flex: 1;
  margin-bottom: 3.125rem;
}

/* Start Mobile Side */
.mobileSide {
  display: none;
  align-items: flex-start;
}

.readMore {
  display: block;
  background: none;
  border: none;
  cursor: pointer;
  color: #388dbc;
  margin: 0 0 1rem 2rem;
}

.deactive {
  display: none;
}

/* Finish Mobile Side */
.btn {
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.33rem;
  padding: 0.5rem 0;
  box-sizing: border-box;
  border-radius: 1rem;
  border: none;
  border: 2px solid var(--primary-color);
  background-color: var(--primary-color);
  color: var(--white-color);
  cursor: pointer;
}

.btnWhite {
  background-color: var(--white-color);
  color: var(--primary-color);
}

/* Main Features */

.mainwrapper {
  border: 1px solid #2e2f41;
  margin-left: 2.66%;
  margin-right: 2.66%;
  border-radius: 1rem;
  padding: 3.35%;
  margin-bottom: 2.25rem;
}

/* Responsive */
@media screen and (max-width: 769px) {
  .sun,
  .backimg,
  .birds,
  .main {
    display: none;
  }

  .plan {
    padding-top: 3rem;
  }

  .headL {
    font-size: 1.875rem;
  }

  .moneyback {
    font-size: 1rem;
  }

  .boxhead {
    font-size: 2.125rem;
    padding-bottom: 0;
    margin-bottom: 0;
  }

  .boxhead small {
    font-size: 1.25rem;
  }
  .action {
    flex-direction: column;
    padding-top: 1rem;
    margin-bottom: 0;
  }

  .price {
    padding-bottom: 0.5rem;
  }

  .pricetable {
    flex-direction: column;
    max-width: 80%;
    margin: auto;
  }

  .box {
    flex-direction: column;
    padding: 1rem;
    width: 100%;
    text-align: center;
  }

  .boxtext {
    margin-bottom: 1.125rem;
    font-size: 1.25rem;
  }
  .boxPrice {
    font-size: 2.125rem;
    margin-bottom: 0.625rem;
    margin-top: 0.625rem;
  }
  .mobileSide {
    display: block;
  }

  .mainwrapper {
    margin-left: 0;
    margin-right: 0;
  }
  .readMore {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1.75rem;
  }
}
@media screen and (max-width: 600px) {
  .pricetable {
    max-width: 90%;
  }
}
