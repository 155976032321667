.contact {
  background-color: var(--bg-light);
  padding-top: 1.875rem;
}

.contactWrapper {
  display: flex;
  justify-content: space-between;
  margin-left: 6%;
  margin-right: 6%;
}

.contactContainer {
  display: flex;
  align-items: center;
  align-self: flex-end;
  justify-content: space-between;
  margin-bottom: 1.75rem;
}

.contactHeader {
  color: rgba(0, 0, 0, 0.7);
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 500;
}

.contactField {
  display: flex;
  align-items: center;
  margin-right: 1.5rem;
}

.info {
  margin-left: 0.5rem;
}

.social {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.social a {
  margin-right: 0.5rem;
}

.flex {
  display: flex;
  flex-direction: column;
}
.form {
  margin-top: 2.25rem;
}

.formElement {
  margin-bottom: 0.625rem;
}

.form > .formElement:first-child {
  display: flex;
  align-items: center;
}

.input {
  display: block;
  width: 100%;
  padding: 0.4125rem 0.625rem;
  border: 1px solid rgba(0, 0, 0, 0.4);
  border-radius: 0.4125rem;
}
.termsContainer {
  display: flex;
  align-items: center;
  margin-top: 0.625rem;
}
.termsContainer label {
  font-size: 0.75rem;
  margin-left: 0.5rem;
}
.term {
  color: var(--primary-color);
  font-size: 0.75rem;
  text-decoration: underline;
}

.button {
  display: block;
  border: none;
  border-radius: 1rem;
  text-decoration: none;
  cursor: pointer;
  padding: 0.3125rem 1rem;
  text-align: center;
  font-size: 1.25rem;
  background-color: var(--primary-color);
  color: var(--white-color);
  margin-left: 0.5rem;
}

.footer {
  background-color: var(--bg-light);
}

.footerWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  margin-left: 6%;
  margin-right: 6%;
  padding-top: 0.625rem;
  padding-bottom: 1.25rem;
  border-top: 1px solid #c4c4c4;
}

.policies {
  display: flex;
  align-items: center;
}

.policies a {
  color: #2f2e41;
  font-weight: 300;
  font-size: 0.75rem;
  margin-right: 1.5rem;
}

.policies a:last-child {
  margin-right: 0;
}

.copyright {
  color: #2f2e41;
  font-size: 0.75rem;
  font-weight: 300;
}

.dark {
  color: var(--white-color);
}

.gold {
  color: var(--golden-color);
}

.bgDark {
  background-color: var(--black-color);
}

.bgGolden {
  background-color: var(--golden-color);
}

@media only screen and (max-width: 768px) {
  .contactWrapper {
    flex-direction: column;
    justify-content: center;
  }

  .contactHeader {
    text-align: center;
  }

  .form {
    width: 20rem;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .contactContainer {
    flex-direction: column;
    align-items: center;
    align-self: auto;
    justify-content: center;
    margin-top: 1.875rem;
    margin-bottom: 1.875rem;
  }

  .contactField {
    margin-bottom: 1rem;
    margin-right: 0;
  }
  .footerWrapper {
    flex-direction: column;
  }
  .policies {
    justify-content: space-between;
    width: 100%;
    margin-bottom: 1rem;
  }

  .policies a {
    margin: 0;
  }
}
