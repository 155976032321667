/* Showcase */
.showcase {
  position: relative;
  margin-bottom: 4rem;
}

.showcaseWrapper {
  display: flex;
  justify-content: center;
  text-align: center;
}

.showcaseContent {
  margin-top: 3.125rem;
  max-width: 75%;
}

.showcaseImg {
  display: block;
  margin-top: 4rem;
  margin-bottom: 2.5rem;
}

.contentHeader {
  font-size: 1.875rem;
  font-weight: 500;
  margin-bottom: 1.375rem;
  color: var(--white-color);
}

.plead {
  margin-top: -1.5rem;
  font-size: 1.25rem;
  color: var(--golden-color);
}

/* Buttons */
.btn {
  width: 20rem;
  font-size: 1.125rem;
  padding: 0.6rem 0;
  border: none;
  border-radius: 0.5rem;
  background: var(--golden-color);
  color: var(--white-color);
  cursor: pointer;
}

/* sections */
.section {
  margin-bottom: 6.5rem;
}

.innerWidth {
  max-width: 75%;
  margin: 0 auto;
}
/* Icons Features*/
.iconsrow {
  display: flex;
  align-items: center;
  justify-content: space-around;
  text-align: center;
}

.icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.icon button {
  background: none;
  border: none;
  cursor: pointer;
}

.icon span {
  display: inline-block;
  color: var(--white-color);
  padding-top: 1rem;
  font-size: 1.25rem;
  font-weight: 500;
}

.brandDescWrapper {
  margin: 2rem auto;
  border: 1px solid #fff;
  border-radius: 0.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4rem 7.25rem;
  min-height: 16.375rem;
}
.brandDesc {
  color: #fff;
}

/* Features & Specification */

.orbpartheader {
  color: var(--white-color);
  font-weight: 500;
  font-size: 1.5rem;
}

.spotbutton {
  padding-top: 3.125rem;
  padding-bottom: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.imgWhite {
  filter: brightness(0) invert(1);
}

@media only screen and (max-width: 768px) {
  .innerWidth {
    max-width: 92%;
  }
  .orbpartheader {
    text-align: center;
  }
  .brandDescWrapper {
    margin: 2rem auto;
    border: 1px solid #fff;
    border-radius: 0.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2.5rem 1.5rem;
    min-height: 27.125rem;
  }
  .icon button img {
    width: 3rem;
    margin-left: auto;
    margin-right: auto;
  }

  .icon button span {
    font-size: 0.875rem;
  }

  .showcaseImg img {
    margin: 0 auto;
  }
}

@media only screen and (max-width: 600px) {
  .btn {
    width: 83.33%;
  }
}
