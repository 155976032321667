.wrapper {
  margin-top: 1.5rem;
  border: 1px solid var(--white-color);
  border-radius: 0.5rem;
  padding: 2.25rem 3.25rem;
}
.item {
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  font-size: 1.25rem;
  color: var(--white-color);
  margin-bottom: 1.25rem;
}

.item span {
  flex-basis: 38%;
}

.wider {
  flex-basis: 58%;
}

@media only screen and (max-width: 768px) {
  .wrapper {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
}
