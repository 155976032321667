.orbvpntext {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
}

.textrow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2.25rem;
}

.textcol {
  display: flex;
  flex-basis: 46%;
  align-items: center;
  color: var(--white-color);
}

.textcol img {
  width: 4.56rem;
  height: 4.56rem;
  margin-right: 1.5rem;
}

.textcol p {
  font-weight: 500;
  font-size: 1.25rem;
}

@media only screen and (max-width: 768px) {
  .textrow {
    align-items: stretch;
  }
  .textcol {
    flex-direction: column;
    align-items: center;
  }
  .textcol p {
    text-align: center;
    margin-top: 1.25rem;
  }
  .textcol img {
    width: 2.25rem;
    height: 2.25rem;
    margin-right: 0;
  }
}
