.navbar {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  background-color: var(--white-color);
}

.dark {
  background-color: var(--black-color);
}

.navWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-left: 6%;
  margin-right: 6%;
}

.nav {
  display: flex;
  align-items: center;
}

.logo {
  flex: 0 0 76px;
}

.hamburger {
  display: none;
}

@media only screen and (max-width: 1024px) {
  .navbar {
    background-color: #fff;
  }
  .dark {
    background-color: rgba(0, 0, 0, 0.3);
  }
  .nav {
    display: none;
  }
  .hamburger {
    display: block;
    cursor: pointer;
    margin-right: 1.25rem;
    font-size: 1.5rem;
  }
  .logo {
    flex: 0 0 48px;
    top: 0;
  }
}
