.screen {
  padding: 2rem 0;
  margin-left: 6%;
  margin-right: 6%;
}
.screen h4 {
  color: var(--primary-color);
  font-size: 1.25rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
}
