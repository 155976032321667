.item {
  margin-right: 2.5rem;
  font-size: 1.25rem;
  font-weight: 300;
}

.button {
  padding: 0.5rem 1.5rem;
  font-weight: 400;
  color: #fff;
  background-color: #348ed3;
  border-radius: 1rem;
  margin-right: 0;
}

.itemWrapper {
  padding: 0;
}

.dark {
  color: var(--white-color);
}

@media only screen and (max-width: 1024px) {
  .itemWrapper {
    text-align: center;
  }
  .item {
    margin-right: 0;
    display: block;
    padding: 1.25rem;
  }

  .button {
    border-radius: 0;
  }
}
