.row ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.row li {
  display: inline-block;
  flex-basis: 23%;
  font-size: 1.125rem;
  text-align: center;
  margin-bottom: 2rem;
}

.row li:first-child {
  text-align: left;
}

.row span {
  font-size: 1.25rem;
  font-weight: bold;
}

.check {
  color: var(--primary-color);
  font-size: 2rem;
}

.cross {
  color: var(--grey-color);
  font-size: 2rem;
}

@media screen and (max-width: 769px) {
  .row li:first-child {
    flex-basis: 60%;
  }
  .row li {
    flex-basis: 40%;
    font-size: 1.125rem;
    color: #333;
  }
  .row span {
    font-weight: 400;
    color: #333;
  }
}
