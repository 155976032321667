.tagline {
  margin: 0.5rem auto;
  text-align: center;
  font-weight: 400;
  font-size: 0.75rem;
  color: rgba(0, 0, 0, 0.38);
}

.dark {
  background-color: transparent;
  color: var(--white-color);
}
.tagline {
  display: none;
}
@media only screen and (max-width: 1024px) {
  .tagline {
    display: block;
  }
}
