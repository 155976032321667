.section1 {
  background-color: var(--black-color);
  color: var(--white-color);
  min-height: 115vh;
}
.part1 {
  min-height: 100vh;
}
.rotation {
  position: absolute;
  top: -25%;
  height: 100vh;
}
.topLogo {
  position: absolute;
  top: 5%;
  left: 5%;
}
.topTitle {
  padding: 25% 0 30%;
}
.topTitle h1 {
  font-size: 1.5rem;
  font-weight: 500;
}
.content {
  width: 75%;
  min-height: 80vh;
  position: relative;
  bottom: 0;
  margin-left: 5%;
  margin-top: 5%;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.title {
  font-size: 2.25rem;
  font-weight: 600;
  color: var(--primary-color);
  margin-bottom: 2rem;
}
.aboutP {
  font-size: 1.5rem;
}

.earthbox {
  position: absolute;
  bottom: 0;
  right: 1rem;
}
.section2,
.section3,
.section4,
.section5 {
  background-color: var(--black-color);
  color: var(--white-color);
  min-height: 100vh;
}
.section2 {
  background-image: url('../../components/assets/company/Stars.svg');
  background-size: auto 100%;
}
.part2 {
  min-height: 100vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3rem 5% 0;
}
.left,
.right {
  flex-basis: 40%;
}
.right p {
  font-size: 1.5rem;
}
.part3 {
  min-height: 100vh;
}
.part3 .title {
  padding-left: 5%;
}
.history {
  display: flex;
  align-items: center;
}
.bigImg {
  display: none;
}
.storyImg {
  position: absolute;
  width: 100%;
  height: 100%;
  padding-top: 5%;
}
.story2007,
.story2009,
.story2012,
.story2013,
.story2020 {
  position: absolute;
  display: block;
}
.bigImg {
  display: none;
}
.story2007 {
  left: 10%;
}
.story2009 {
  left: 50%;
  top: 10%;
}
.story2012,
.story2013 {
  right: 1rem;
}
.story2012 {
  display: block;
  top: 35%;
}
.story2013 {
  bottom: 10%;
}
.story2020 {
  left: 20%;
  bottom: 0;
}

.storys {
  margin-top: 35%;
  margin-bottom: 40%;
  padding-left: 5%;
  z-index: 1;
}
.story {
  display: flex;
  width: 60%;
  margin: 2rem 0;
}
.year {
  font-size: 2.25rem;
  padding-right: 2rem;
  background: -webkit-linear-gradient(#7891fc, #db81f1);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.storyP {
  font-size: 1.5rem;
}

.part4 {
  min-height: 100vh;
}
.company {
  padding-left: 5%;
}
.culture {
  width: 75%;
}
.cultureTitle {
  display: flex;
  align-items: flex-start;
  font-size: 2.25rem;
  font-weight: 500;
}
.cultureTitle h3 {
  padding-left: 3rem;
}
.cultureContent {
  font-size: 1.5rem;
  line-height: 2.25rem;
  padding-left: 8rem;
  margin: 2rem 0;
}

.part5 {
  min-height: 100vh;
}
.careers {
  width: 65%;
  position: relative;
  padding-left: 5%;
  z-index: 1;
}
.carrersTitle {
  color: #ebbe33;
  font-size: 1.5rem;
  text-decoration: underline;
}
.carrersP {
  font-size: 1.5rem;
}
.mailto {
  color: var(--primary-color);
}
.sun {
  position: absolute;
  top: 0;
  right: 0;
}
.newStars {
  display: block;
  margin: 4rem 0 8rem;
  padding-left: 5%;
}
.newStarsMob {
  display: none;
}

.story2012mob {
  display: none;
}
@media screen and (max-width: 900px) {
  .storyImg img {
    scale: 70%;
    transform: scale(70%);
  }
}

@media screen and (max-width: 768px) {
  .content {
    width: 85%;
  }
  .earthbox {
    opacity: 0.6;
  }
  .section2 {
    background-image: none;
  }
  .part2 {
    flex-direction: column;
    align-items: flex-start;
  }

  .left,
  .right {
    flex-basis: 100%;
    padding: 3rem 0;
  }
  .right p {
    text-align: justify;
  }

  .history {
    flex-direction: column;
  }

  .storyImg {
    position: relative;
    width: 100%;
    min-height: 100vh;
    padding-top: 5%;
    display: none;
  }
  .bigImg {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .bigImg img {
    width: 100%;
    padding: 0 5%;
  }
  .storyImg img {
    scale: 100%;
    transform: scale(100%);
  }
  .story2007,
  .story2009,
  .story2012,
  .story2013,
  .story2020 {
    display: none;
  }
  .storys {
    padding: 0 5%;
    text-align: center;
  }
  .story {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-content: center;
  }
  .year {
    padding-right: 0;
  }
  .title {
    padding-left: 5%;
  }
  .culture {
    width: 100%;
    padding: 0 5%;
  }
  .company {
    padding-left: 0;
  }
  .cultureTitle h3 {
    font-size: 1.5rem;
  }

  .cultureContent {
    padding-left: 0;
    font-size: 0.85rem;
  }

  .careers {
    width: 100%;
    padding: 0 5%;
    margin-bottom: 10%;
  }
  .carrersTitle {
    margin-bottom: 2rem;
  }
  .carrersP a {
    display: block;
  }
  .sun {
    width: 180px;
    height: 200px;
    top: 40%;
  }
  .newStars {
    display: none;
  }
  .newStarsMob {
    display: block;
    padding: 0 5%;
  }
  .newStarsMob img {
    width: 100%;
  }
}

/* @media screen and (max-width: 500px) {
  .story2007mob {
    left: 10%;
  }
  .story2009mob {
    left: 40%;
    top: 7%;
  }
  .story2012mob {
    right: 10%;
    top: 25%;
  }
  .story2013mob {
    top: 50%;
    right: calc(100vw - 85%);
  }
  .story2020mob {
    top: 65%;
    left: 10%;
  }
} */
